@media (max-width: 900px) {

  html {
    font-size: calc(1vw + 0.55rem) !important;
  }
  .custom-padding-desktop {
    padding: 0 !important;
  }
}

.container {
  --bs-gutter-x: 0rem !important;
}
@media (min-width: 1800px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px !important;
  }
}

@font-face {
  font-family: "Quitos";
  src: url("./quitos-font/Quitos.otf") format("opentype"),
    url("./quitos-font/Quitos.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'Bambe-Swash';
  src: url('../BambeFont/bambe-swash.otf') format('opentype'),
       url('../BambeFont//bambe-swash.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

.quitos {
  font-family: "Quitos", sans-serif !important;
}
.inter {
  font-family: "Garamond, Georgia, 'Adobe Caslon Pro',Quitos, san-serif" !important;
}
.lato {
	font-family: "Lato", sans-serif !important;
}
.poppin {
	font-family: "Poppins", sans-serif !important;
}
.gray {

  color: #696969;

}
.lato-thin {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.lato-light {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.lato-regular {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.lato-bold {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.lato-black {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.lato-thin-italic {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.lato-light-italic {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.lato-regular-italic {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.lato-bold-italic {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.lato-black-italic {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: italic;
}

.poppins-thin {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: italic;
}

/* .App {
  text-align: center;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


*/
.custom-btn {
  background: #ef960b;
  border: 1px solid black;
  border-radius: 18px;
  padding: 3.5px 13px;
  cursor: pointer;
  color: black;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 18px;

  /* margin-top: 13px;  */
}
.custom-btn-title {
  margin: 0;
  font-size: 17px;
}
.logo-img {
	width: 250px;
	height: 70px;
}
.navbar-row {
	display: contents;
	width: 100%;
}
ul {
	display: flex;
	justify-content: right;
	margin-bottom: 0 !important;
}
ul li {
	list-style: none;
	margin-left: 10px;
	margin-right: 10px;
}
.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0;
  height: 100%;
}
.nav-item .nav-links:hover {
	border-bottom: #ef960b;
}
.text-gray {
	color: #929090;
}
.logo-portion {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.footer--contact-list li {
	margin-top: 10px;
}
.footer--services-list li {
	margin-top: 10px;
}
.footer-img {
	width: 150px;
}
.experience-container {
	background-color: #ef960b;
}
.experience-btn {
	display: inline-block;
	background: #fff;
	border: 1px solid #fff;
	border-radius: 20px;
	padding: 2px 13px;
	cursor: pointer;
	margin-top: 13px;
}
.trip-btn {
  display: inline-block;
  background: #ef960b;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 13px;
  font-size: 25px;
}
.away-btn {
  display: inline-block;
  background: #ef960b;
  border-radius: 30px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 13px;
  font-size: 25px;
}
.banner-container {
	background-image: url("./images/baneer-img.png");
	height: 77vh;
	background-size: cover;
	background-position: center;
}
.card {
	border: 1px solid black !important;
	border-radius: 0px !important;
}
.card-title {
	color: #bcbcbc !important;
}
.card-text {
	font-size: 14.5px;
	color: #929090;
}
.card-img-top {
	width: 50px !important;
	height: 50px !important;
}
.quality-container {
	transform: translateY(-35%);
}
.list-conatiner {
	display: flex;
	justify-content: end;
}
.quality-text {
  font-weight: normal;
  font-size: 60px !important;
  font-family: "Poppins";
}
.small-text {
  font-size: 20.5px;
  color: #919191;
  font-family: "Lato";
}
.card-body {
	height: 250px;
	width: 350px;
	border: 1px solid black;
	border-right-color: #707070;
}
.text-experience,
.text-services,
.text-away,
.text-dynamic {
	color: #696969;
}
.storelogo {
	width: 85%;
}

.footer-icons svg {
	width: 23px;
	height: 23px;
}
.footer-title {
	font-size: 27px;
}
.mobile-navbar {
	display: none !important;
}
.qualities-bg {
	background-color: #cccccc;
}
.grid-container {
	display: grid;
	grid-template-columns: auto auto auto auto;
	padding: 10px;
	gap: 10px;
}
.grid-box {
	background-color: #e4e4e4;
	padding: 25px;
	font-size: 30px;
	/* text-align: center; */
}
.grid-item {
	background-color: rgba(255, 255, 255, 0.8);
	padding: 25px;
	font-size: 30px;
	text-align: center;
}
.grid-text {
	font-size: 10px;
	color: #a5a5a5;
	/* color: #0a66c2; */
}
.detail-grid-text {
	font-size: 13px;
	color: black;
}
.grid-text-container {
	line-height: 1;
	margin-top: 40px;
}
.van-img {
  width: 90%;
  height: 100px;
  right: 10%;
  position: absolute;
  top: 27%;
}
.trip-container {
	background-image: url("./images/curve.png");
	height: 100vh;
	background-size: cover;
	background-position: center;
}
.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 100vh;  */
}

.col-lg-6 {
	max-width: 600px; /* Adjust width as necessary */
	width: 100%;
}
.tap-container {
	background-color: black;
}
.crousel-background {
	background-color: #469187;
	height: 200px;
}
.text_yellow {
	color: #ef960b;
}
.dynamic-img {
	width: 100%;
}
/* .nav-item {
  position: relative;
} */
.dropdown-list {
  position: absolute;
  background: white;
  color: black;
  padding: 10px;
  border: 0.5px solid #ccc;
  box-shadow: 0px 1px 1px #00000029;
}
.dropdown-list-mobile {
  position: absolute;
  background: white;
  color: black;
  padding: 10px;
  border: 0.5px solid #ccc;
  box-shadow: 0px 1px 1px #00000029;
  top: 55px;
}
.dropdown-list-services-mobile {
  position: absolute;
  background: white;
  color: black;
  padding: 10px;
  border: 0.5px solid #ccc;
  box-shadow: 0px 1px 1px #00000029;
  top: 245px;
}
/* Dropdown.css */

.dropdown-item {
  margin-left: -1px;
  font-size: medium;
  position: relative;
}
.dropdown-item-mobile {
  font-size: medium;
  position: relative;
  padding: 15px;
  color: grey;
}
.dropdown-item-services-mobile {
  font-size: 13px;
  position: relative;
  padding: 15px;
  color: grey;
}

/* .dropdown-item:not(:first-child)::before {
  content: "";
  display: block;
  width: calc(100% + 2px);
  margin-left: -1px;
  /* height: 0.5px; */
/* background-color: #469187;  */
/* margin-top: 5px;  */
/* } */

.dropdown-item:not(:last-child)::after {
  content: "";
  position: relative;
  top: 30px;
  display: block;
  width: calc(125% + 2px);
  margin-left: -1px;
  height: 0.5px;
  opacity: 0.5;
  /* margin-top: 7px; */
  background-color: #469187;
  margin-bottom: 5px;
}
/*.dropdown-item-mobile:not(:last-child)::after {
  content: "";
  position: relative;
  top: 30px;
  display: block;
  width: calc(55% + 2px);
  margin-left: -1px;
  height: 0.5px;
  opacity: 0.5;
  /* margin-top: 7px; */
/* background-color: #469187; */
/* margin-bottom: 5px; */
/* } */
.dropdown-item-mobile:not(:first-child)::before {
  content: "";
  display: block;
  width: calc(100% + 2px);
  margin-left: -1px;
  height: 0.5px;
  opacity: 0.5;
  background-color: #469187;
  position: relative;
  top: -15px;
}
/* .dropdown-item-services-mobile:not(:first-child)::before {
  content: "";
  display: block;
  width: calc(100% + 2px);
  margin-left: -1px;
  height: 0.5px;
  opacity: 0.5;
  background-color: #469187;
  position: relative;
  top: -15px;
} */
.dropdown-item-services-mobile:nth-child(n+3)::before {
  content: "";
  display: block;
  width: calc(100% + 2px);
  margin-left: -1px;
  height: 0.5px;
  opacity: 0.5;
  background-color: #469187;
  position: relative;
  top: -15px;
}

.with-arrow {
  padding-top: 20px; /* Adjust padding to make space for the arrow */
}

/* .arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -60px;
  left: '50%';
  transform: translateX(-50%);
} */

@media screen and (max-width: 780px) {
  .card-body {
    height: auto !important;
  }
  .logo-portion {
    display: flex;
    justify-content: space-between;
  }
  .list-conatiner {
    justify-content: center !important;
  }
  .main-list {
    display: flex;
    flex-direction: column;
  }
  .nav-links {
    padding: 1.5rem 1rem !important;
  }
  ul li {
    margin-right: 0px !important;
  }
  .custom-btn {
    padding: 7px 5rem !important;
  }
  .view-height {
    height: 100vh !important;
    display: block !important;
  }
  .card-container {
    display: flex;
    justify-content: center;
  }
  .card-body {
    border: 0px;
  }
  .desktop-navbar {
    display: none !important;
  }
  .mobile-navbar {
    display: block !important;
  }
  .quality-container {
    transform: translateY(2%);
  }
  .grid-container {
    grid-template-columns: auto;
  }
  .trip-imges {
    display: none;
  }
}
h1 {
  font-size: 4.375rem !important;
}

.row {
  width: 100%;
}

.one-trip-row {
  --bs-gutter-x: 0 !important;
}

.service-dropdown {
  width: 915px;
  height: 523px;
  justify-content: center;
  z-index: 10;
}
.service-dropdown .img-holder {
  width: 30%;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
  /* max-height: 200px; */
  min-height: 150px;
  min-width: 200px;
}
.service-dropdown ul {
  flex-wrap: wrap;
}
.custom-padding-desktop {
  padding: 20px;
}
.custom-height {
  left: 655px;
  top: 175px;
}
.custom-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 50px; */
  grid-column-gap: 150px;
  margin-left: 43px;
}
.custom-grid-services {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.custom-dropdown-item {
  top: 40px;
  color: gray !important;
}
.custom-p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.custom-grid a:hover {
  font-weight: bold !important;
  color: black !important;
}
.custom-about-us-ul a:hover {
  font-weight: bold !important;
  color: black !important;
}

.about-us-dropdown {
  width: 300px;
  height: 300px;
  margin-left: 100px;
}
.custom-height-about-us {
  left: 835px;
  top: 175px;
}
/* .arrow-up-about-us {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -49px;
  left: 143px;
  transform: translateX(-50%);
} */
.custom-dropdown-item-about-us {
  margin-bottom: 22px;
  margin-top: 29px;
  color: grey !important;
}
.custom-about-us-ul {
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.arrow-up-about-us-mobile {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -20px;
  left: 57px;
  transform: translateX(-50%);
}
.arrow-up-services-mobile {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -20px;
  left: 53px;
  transform: translateX(-50%);
}
/* @media (min-width: 767px){
  .custom-height {
    left: 448px !important;
  }
} */
/* For screens between 992px and 1199px (desktops) */
@media (min-width: 1512px) {
  .custom-height {
    left: 565px !important;
  }
  .custom-height-about-us{
    left: 750px !important;
  }
}
