.footer {
  background-color: #000;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}
/* .footer .container {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
} */
.footer-logo-portion {
}
/* .footer__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1300px;
    margin: 0 auto;
  } */

.footer--services {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer--services-list,
.footer--contact-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storelogo{
  height: 44px;
  width: 240px;
}
