.topbar {
  width: 30px;
}
.active{
  border-bottom: 2px solid #EF960B !important;
}
.navbar {
  background: #0b0b0b;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem; */
  /* position: sticky;
  top: 0;
  z-index: 999; */
}
.hovered-margin{
  margin-top: 25px;
}

/* .navbar container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  height: 200px;
} */

/* .navbar-logo {
    justify-self: start;
    cursor: pointer;
    margin-left: 60px;
    text-decoration: none;
    display: flex;
  
    height: 76px;
    float: left;
  } */
/* 
.fa-servicestack {
  margin-left: 0.75rem;
  font-size: 1.8rem;
} */

/* .nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-right: 2rem;
} */

/* .nav-item {
  height: 40px;
} */

.nav-links:hover {
  /* font-weight: 400;
  border-radius: 0; */
  /* border-bottom: 5px solid white;
  transition: 200ms; */
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* @media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 93vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .nav-item {
    height: 80px;
  }
} */
